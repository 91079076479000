import { useCallback, useMemo } from 'react';
import { IconButton } from '@mui/material';

import { FCX } from '@models';
import { IconPencil } from '@svg/index';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigItem } from '@components/Table/TableConfig';
import Button from '@components/Button';
import { ExtendedSurvey, GetSurveysResponse } from '@core/api/useSurveys';
import { formatDefaultDate, formatNumber } from '@core/utils/string';
import useCommonEnums from '@core/hooks/useCommonEnums';
import useTableSort from '@core/hooks/useTableSort';

enum SurveyStatus {
    Draft = 0,
    Live = 1,
    Closed = 2,
}

const SurveysTableConfig: FCX<{
    data: GetSurveysResponse;
}> = ({
    data,
    children
}) => {
    const { findCommonNameById } = useCommonEnums();

    const {
        sortedResults,
        sortConfig
    } = useTableSort(data);

    const tableRowColor = useCallback((survey: ExtendedSurvey) => {
        if (survey?.statusId === SurveyStatus.Draft) {
            return { color: '#00a3ff' };
        }

        if (survey?.statusId === SurveyStatus.Live) {
            return { color: '#00AE7A' };
        }

        return {};
    }, []);

    const renderActionButton = useCallback((survey: ExtendedSurvey) => {
        if (survey?.statusId === SurveyStatus.Draft) {
            return (
                <Button
                    className="Surveys__button-launch"
                    modifiers={['rounded']}
                    onClick={() => console.log('launch')}
                >
                    Launch
                </Button>
            );
        }

        if (survey?.statusId === SurveyStatus.Live) {
            return (
                <Button className="Surveys__button-close" modifiers={['rounded']} onClick={() => console.log('close')}>
                    Close
                </Button>
            );
        }

        return null;
    }, []);

    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'surveyId',
                title: 'Survey ID',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>{sortedResults?.[index]?.id}</div>
                ),
                width: '70px',
            },
            {
                key: 'fieldingName',
                sortingColName: 'name',
                title: 'Fielding Name',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>{sortedResults?.[index]?.name}</div>
                ),
                width: '140px',
            },
            {
                key: 'dateCreated',
                sortingColName: 'createdDate',
                title: 'Date Created',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatDefaultDate(sortedResults?.[index]?.createdDate)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'fieldingStartDate',
                sortingColName: 'startDate',
                title: 'Fielding Start Date',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatDefaultDate(sortedResults?.[index]?.startDate)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'fieldingEndDate',
                sortingColName: 'endDate',
                title: 'Fielding End Date',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatDefaultDate(sortedResults?.[index]?.endDate)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'status',
                title: 'Status',
                Value: ({ index }) => {
                    if (sortedResults?.[index]?.statusId) {
                        return (
                            <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                                {findCommonNameById('surveyStatuses', data[index].statusId as number)}
                            </div>
                        );
                    }

                    return null;
                },
                width: '65px',
            },
            {
                key: 'express',
                sortingColName: 'expressCount',
                title: 'Express',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.expressCount)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'fullTest',
                sortingColName: 'fullTestCount',
                title: 'Full Test',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.fullTestCount)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'publicItems',
                sortingColName: 'publicItemsCount',
                title: 'Public Items',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.publicItemsCount)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'totalItems',
                sortingColName: 'totalItemsCount',
                title: 'Total Items',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.totalItemsCount)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'sampleSize',
                sortingColName: 'completedSampleSize',
                title: 'Sample Size',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.completedSampleSize)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'customSampleType',
                title: 'Custom Sample Type',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {sortedResults?.[index]?.customSampleTypeId}
                    </div>
                ),
                width: '80px',
            },
            {
                key: 'action',
                width: '120px',
                title: 'Actions',
                Value: ({ index }) => (
                    <div className="Surveys__table-actions">
                        <IconButton
                            onClick={() => {
                                console.log('Edit', sortedResults?.[index]);
                            }}
                        >
                            <IconPencil />
                        </IconButton>
                        {renderActionButton(sortedResults?.[index])}
                    </div>
                ),
            },
        ],
        [data, findCommonNameById, renderActionButton, sortedResults, tableRowColor],
    );

    return <TableContext.Provider value={{ config: tableConfig, sortConfig }}>{children}</TableContext.Provider>;
};

export default SurveysTableConfig;
