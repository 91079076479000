import useAxios from '@core/hooks/useFetch';
import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@core/models';

import { components, paths } from './api';

const GET_CHAINS = '/api/ChainDetails/';
const DELETE_MENU_ITEM = '/api/ChainDetails/DeleteMenuItem';
const UPSERT_MENU_ITEM = '/api/ChainDetails/UpsertMenuItem';

type GetChainDetails = paths[typeof GET_CHAINS][MethodsEnum.GET];
export type GetChainDetailsResponse = GetChainDetails[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];
export type ChainDetailsMenuItem = components['schemas']['ChainDetailMenuItem'];

type PostUpsertMenuItem = paths[typeof UPSERT_MENU_ITEM][MethodsEnum.POST];
export type PostUpsertMenuItemResponse =
    PostUpsertMenuItem[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];
export type PostUpsertMenuItemRequest = PostUpsertMenuItem[OperationsEnum.REQUEST][Content][ContentTypeEnum.FormData];

export function useGetChainDetails(chainId?: string) {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetChainDetailsResponse>({
        url: `${GET_CHAINS}${chainId}`,
        method: MethodsEnum.GET,
    });

    return { data, isLoading, error, doFetch } as const;
}

export function useDeleteMenuItem() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios({
        url: DELETE_MENU_ITEM,
        method: MethodsEnum.DELETE,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}

export function useUpsertMenuItem() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<PostUpsertMenuItemResponse, PostUpsertMenuItemRequest>({
        url: UPSERT_MENU_ITEM,
        method: MethodsEnum.POST,
        lazy: true,
        initialConfig: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    });

    return { data, isLoading, error, doFetch } as const;
}
