import dayjs, { Dayjs } from 'dayjs';
import { NumberFormatBase } from 'react-number-format';
import { useNotifications } from '@toolpad/core/useNotifications';
import { FormEvent, useCallback, useEffect, useReducer } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FCX } from '@core/models';
import { Select } from '@components/Inputs/Select';
import { currencyFormatter } from '@core/utils/string';
import { ImageInput } from '@components/Inputs/ImageInput';
import { ChainDetailsMenuItem, GetChainDetailsResponse, useUpsertMenuItem } from '@core/api/useChainDetails';

import './ChainItemPopup.scss';
import useCommonEnums from '@core/hooks/useCommonEnums';
import { useParams } from 'react-router-dom';

type ReducerAction = {
    type: 'SET_FIELD' | 'SET_FIELDS';
    fieldName?: keyof ReducerState;
    fieldValue?: string | Dayjs | null;
    fields?: ReducerState;
};

type ReducerState = {
    image: string;
    displayDate: Dayjs | null;
    entryDate: Dayjs | null;
    itemName: string;
    itemDescription: string;
    note: string;
    price: string;
    status: string;
    itemType: string;
    uiStatus: string;
};

const customStatuses = [
    { id: '1', title: 'Live' },
    { id: '2', title: 'Draft' },
];

const defaultState: ReducerState = {
    image: '',
    entryDate: dayjs(new Date()),
    displayDate: dayjs(new Date()).add(1, 'month'),
    itemName: '',
    itemDescription: '',
    note: '',
    price: '',
    status: '3', // Default to 'Limited Time Offer'
    itemType: '',
    uiStatus: customStatuses[0].id,
};

const ChainItemPopup: FCX<{
    open: boolean;
    handleClose: (_shouldRefreshItems?: boolean) => void;
    initialData?: ChainDetailsMenuItem;
    chainDetailsData: GetChainDetailsResponse | null;
}> = ({
    open,
    handleClose,
    initialData,
    chainDetailsData
}) => {
    const { id: chainId } = useParams();
    const { common } = useCommonEnums();
    const { doFetch } = useUpsertMenuItem();
    const notifications = useNotifications();

    const formReducer = (state: ReducerState, action: ReducerAction) => {
        switch (action.type) {
            case 'SET_FIELD':
                if (!action.fieldName) {
                    return state;
                }
                return {
                    ...state,
                    [action.fieldName]: action.fieldValue,
                };
            case 'SET_FIELDS':
                return {
                    ...state,
                    ...action.fields,
                };
            default:
                return state;
        }
    };

    const [formState, dispatch] = useReducer(formReducer, {
        image: '',
        displayDate: null,
        entryDate: dayjs(new Date()),
        itemName: '',
        itemDescription: '',
        note: '',
        price: '',
        status: '',
        itemType: '',
        uiStatus: '',
    });

    const mapCommonToSelectOptions = useCallback(
        (commonType: string) =>
            common?.[commonType as keyof typeof common]?.map((item) => ({
                title: item?.name ?? '',
                id: item?.id?.toString() ?? '',
            })) ?? [],
        [common],
    );

    useEffect(() => {
        if (initialData) {
            dispatch({
                type: 'SET_FIELDS',
                fields: {
                    image: initialData?.smallImageUrl ?? '',
                    displayDate: dayjs(initialData.displayDate),
                    entryDate: dayjs(initialData.entryDate),
                    itemName: initialData?.name ?? '',
                    itemDescription: initialData?.description ?? '',
                    note: initialData?.note ?? '',
                    price: initialData?.price ?? '',
                    status: initialData?.kindId?.toString() ?? '',
                    itemType: initialData?.categoryId?.toString() ?? '',
                    uiStatus: initialData?.statusId?.toString() ?? '',
                },
            });
        } else {
            dispatch({
                type: 'SET_FIELDS',
                fields: defaultState,
            });
        }
    }, [initialData]);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            className="ChainItemPopup"
            classes={{
                paper: 'ChainItemPopup__container',
            }}
            PaperProps={{
                component: 'form',
                onSubmit: (event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    doFetch({
                        data: {
                            SipChainCode: chainId,
                            FFChainId: chainDetailsData?.ffChainId ?? 0,
                            Image: formState.image,
                            Id: initialData?.id ?? 0,
                            EntryDate: formState.entryDate?.toISOString(),
                            DisplayDate: formState.displayDate?.toISOString(),
                            Name: formState.itemName,
                            Description: formState.itemDescription,
                            Note: formState.note,
                            Price: formState.price,
                            KindId: parseInt(formState.status),
                            CategoryId: parseInt(formState.itemType),
                            StatusId: parseInt(formState.uiStatus),
                        },
                    }).then((res) => {
                        if (res?.status === 200 && res?.data?.errors?.length === 0) {
                            notifications.show('Item saved successfully', {
                                severity: 'success',
                                autoHideDuration: 4500,
                            });
                        } else {
                            notifications.show('Item save failed', {
                                severity: 'error',
                                autoHideDuration: 4500,
                            });
                        }

                        dispatch({
                            type: 'SET_FIELDS',
                            fields: defaultState,
                        });

                        handleClose(true);
                    });
                },
            }}
        >
            <DialogTitle>{initialData ? 'Edit Item' : 'Add Item'}</DialogTitle>
            <DialogContent>
                <div className="ChainItemPopup__form-body">
                    <div className="ChainItemPopup__form-row">
                        <ImageInput
                            onImageSelectedCallback={(imgString) => {
                                dispatch({
                                    type: 'SET_FIELD',
                                    fieldName: 'image',
                                    fieldValue: imgString,
                                });
                            }}
                            imageUrl={formState.image}
                        />
                        <div className="ChainItemPopup__date-container">
                            <DatePicker
                                label="Display Date"
                                views={['month', 'year']}
                                format="MMM YY"
                                value={formState.displayDate}
                                onChange={(date) => {
                                    dispatch({
                                        type: 'SET_FIELD',
                                        fieldName: 'displayDate',
                                        fieldValue: date,
                                    });
                                }}
                            />
                            <DatePicker
                                label="Entry Date"
                                value={formState.entryDate}
                                onChange={(date) => {
                                    dispatch({
                                        type: 'SET_FIELD',
                                        fieldName: 'entryDate',
                                        fieldValue: date,
                                    });

                                    if (date) {
                                        dispatch({
                                            type: 'SET_FIELD',
                                            fieldName: 'displayDate',
                                            fieldValue: date.add(1, 'month'),
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="itemName"
                            name="itemName"
                            label="Item Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formState.itemName}
                            onChange={(event) =>
                                dispatch({
                                    type: 'SET_FIELD',
                                    fieldName: 'itemName',
                                    fieldValue: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <TextField
                            id="itemDescription"
                            name="itemDescription"
                            label="Item Description"
                            fullWidth
                            multiline
                            variant="standard"
                            value={formState.itemDescription}
                            onChange={(event) =>
                                dispatch({
                                    type: 'SET_FIELD',
                                    fieldName: 'itemDescription',
                                    fieldValue: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <TextField
                            id="note"
                            name="note"
                            label="Note"
                            fullWidth
                            multiline
                            maxRows={2}
                            minRows={1}
                            variant="standard"
                            value={formState.note}
                            onChange={(event) =>
                                dispatch({
                                    type: 'SET_FIELD',
                                    fieldName: 'note',
                                    fieldValue: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <NumberFormatBase
                            customInput={TextField}
                            format={currencyFormatter}
                            prefix={'$'}
                            {...{
                                id: 'price',
                                name: 'price',
                                type: 'text',
                                label: 'Price',
                                fullWidth: true,
                                variant: 'standard',
                            }}
                            value={formState.price}
                            onValueChange={({ formattedValue }) =>
                                dispatch({
                                    type: 'SET_FIELD',
                                    fieldName: 'price',
                                    fieldValue: formattedValue,
                                })
                            }
                        />

                        <Select
                            label="Status"
                            id="status"
                            value={formState.status}
                            setValue={(value) => {
                                dispatch({
                                    type: 'SET_FIELD',
                                    fieldName: 'status',
                                    fieldValue: value,
                                });
                            }}
                            options={mapCommonToSelectOptions('itemKinds')}
                        />
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <Select
                            label="Item Type"
                            id="itemType"
                            value={formState.itemType}
                            setValue={(value) => {
                                dispatch({
                                    type: 'SET_FIELD',
                                    fieldName: 'itemType',
                                    fieldValue: value,
                                });
                            }}
                            options={mapCommonToSelectOptions('itemCategories')}
                        />
                        <Select
                            label="UI Status"
                            id="uiStatus"
                            value={formState.uiStatus}
                            setValue={(value) => {
                                dispatch({
                                    type: 'SET_FIELD',
                                    fieldName: 'uiStatus',
                                    fieldValue: value,
                                });
                            }}
                            options={customStatuses}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={() => handleClose()}>
                    Cancel
                </Button>
                <Button type="submit">Save & Exit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChainItemPopup;
