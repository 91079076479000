import { CSSProperties, FC, ReactNode } from 'react';

export type ID = number | string;

export enum Sort {
    Asc = 0,
    Desc = 1,
}

export interface iSortingOption {
    id: ID;
    name: string;
    defaultDirection?: Sort;
}

export interface iOption {
    id: ID;
    title: string;
}

export interface FCAdditionalProps {
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
    id?: string;
}

export type FCX<T = object> = FC<FCAdditionalProps & T>;

export type Content = 'content';

export enum ContentTypeEnum {
    FormData = 'multipart/form-data',
    Json = 'application/json',
}

export enum MethodsEnum {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
}

export enum OperationsEnum {
    RESPONSES = 'responses',
    PARAMETERS = 'parameters',
    REQUEST = 'requestBody',
}
