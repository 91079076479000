import { FCX } from '@core/models';
import TextField from '@mui/material/TextField';
import { useCallback } from 'react';

export enum TextInputType {
    TEXT = 'text',
    NUMBER = 'number',
}

const NUMBER_REGEX = /^[0-9\b]+$/;

const TextInput: FCX<{
    type?: TextInputType;
    value: string;
    setValue: (_value: string) => void;
    placeholder?: string;
    isDisabled?: boolean;
}> = ({
    type = TextInputType.TEXT,
    value,
    setValue,
    placeholder,
    isDisabled = false
}) => {
    const onChange = useCallback(
        (value: string | undefined) => {
            if (value === undefined) {
                return;
            }

            if (type === TextInputType.NUMBER) {
                if (value === '' || NUMBER_REGEX.test(value)) {
                    return setValue(value);
                }

                return;
            }

            setValue(value);
        },
        [setValue, type],
    );

    return (
        <TextField
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            disabled={isDisabled}
            classes={{
                root: 'TextInput',
            }}
            InputProps={{
                classes: {
                    root: 'TextInput__root',
                    input: 'TextInput__input',
                },
            }}
            fullWidth
        />
    );
};

export default TextInput;
