import { ChangeEventHandler, useEffect, useState } from 'react';
import cn from 'classnames';
import Button from '@mui/material/Button';

import { FCX } from '@core/models';
import { fileToDataString } from '@core/utils/string';

const ImageInput: FCX<{
    onImageSelectedCallback?: (_base64img: string) => void;
    imageUrl?: string;
}> = ({
    onImageSelectedCallback,
    imageUrl
}) => {
    const [previewImgUrl, setPreviewimgUrl] = useState('');

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
        const file = event.target.files as FileList;
        if (!file) {
            return;
        }

        try {
            const imgUrl = await fileToDataString(file?.[0]);
            setPreviewimgUrl(imgUrl);
            onImageSelectedCallback?.(imgUrl);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (imageUrl) {
            setPreviewimgUrl(imageUrl);
        }
    }, [imageUrl]);

    return (
        <div className="ImageInput">
            <div
                className={cn('ImageInput__preview', {
                    active: !!previewImgUrl,
                })}
            >
                {previewImgUrl && <img src={previewImgUrl} />}
            </div>
            <Button className="ImageInput__button" component="label" role={undefined} tabIndex={-1}>
                Upload image
                <input className="ImageInput__hidden" type="file" accept="image/*" onChange={handleFileChange} />
            </Button>
        </div>
    );
};

export default ImageInput;
