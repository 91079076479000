import { Input, MenuItem, Select } from '@mui/material';

import { Header } from '@vms/Header';
import { IconPlus } from '@svg/index';
import Button from '@components/Button';
import { Table } from '@components/Table';
import { Route } from '@core/routes/Route';
import { TextInput } from '@components/Inputs';
import { ButtonType } from '@components/Button/Button';
import useTableQuickSearch from '@core/hooks/useTableQuickSearch';

import SurveyNewTableConfig from './SurveyNewTableConfig';
import { useState } from 'react';

const SurveyNew = () => {
    const [surveyName, setSurveyName] = useState('');
    const {
        searchValue,
        setSearchValue,
        // searchResults
    } = useTableQuickSearch([], (s) => s);

    return (
        <div className="SurveyNew">
            <Input
                placeholder="Survey Name"
                value={surveyName}
                className="SurveyNew__name-field"
                onChange={(event) => setSurveyName(event.target.value)}
                sx={{ fontSize: '34px' }}
            />
            <div className="SurveyNew__secondary-title">Fielding Parameters</div>
            <div className="SurveyNew__fielding-container">
                <div className="SurveyNew__param-column">
                    <div className="SurveyNew__param-title">Survey Status:</div>
                    <div className="SurveyNew__param-title">Fielding start date:</div>
                    <div className="SurveyNew__param-title">Fielding end date:</div>
                    <div className="SurveyNew__param-title" style={{ marginTop: '4px' }}>
                        custom fielding:
                    </div>
                </div>
                <div className="SurveyNew__param-value-column">
                    <div className="SurveyNew__param-value">Draft</div>
                    <div className="SurveyNew__param-value">11-11-24</div>
                    <div className="SurveyNew__param-value">11-30-24</div>
                    <div className="SurveyNew__param-value">
                        <Select value={''} displayEmpty sx={{ height: '28px', fontSize: '14px' }}>
                            <MenuItem value="">Options</MenuItem>
                            <MenuItem value={10}>21+</MenuItem>
                            <MenuItem value={20}>Gen Z</MenuItem>
                            <MenuItem value={30}>Millenial</MenuItem>
                            <MenuItem value={30}>Household with children</MenuItem>
                        </Select>
                    </div>
                </div>
                <div className="SurveyNew__spacer"></div>
                <div className="SurveyNew__param-column">
                    <div className="SurveyNew__param-title">express items:</div>
                    <div className="SurveyNew__param-title">express+ items:</div>
                    <div className="SurveyNew__param-title">full concepts:</div>
                    <div className="SurveyNew__param-title">public items:</div>
                    <div className="SurveyNew__param-title">other items:</div>
                    <div className="SurveyNew__param-title uppercase">total items in this survey:</div>
                </div>
                <div className="SurveyNew__param-value-column">VALUE</div>
                <div>b</div>
            </div>
            <div className="Separator" />
            <Header
                rightContent={
                    <div className="ChainItems__actions">
                        <Button type={ButtonType.Link} modifiers={['h-tall']} link={Route.SurveyNew}>
                            <IconPlus />
                            New Survey
                        </Button>
                        <TextInput value={searchValue} setValue={setSearchValue} placeholder="Search" />
                    </div>
                }
            >
                <span className="SurveyNew__secondary-title">included items</span>
            </Header>

            <div style={{ height: '480px' }}>
                <SurveyNewTableConfig data={[]}>
                    <Table rowsCount={0} isLoading={false} />
                </SurveyNewTableConfig>
            </div>
        </div>
    );
};

export default SurveyNew;
