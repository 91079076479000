import { useMemo } from 'react';
import { IconButton } from '@mui/material';

import { FCX } from '@models';
import { IconTrash } from '@svg/index';
import { formatDefaultDate } from '@core/utils/string';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigColumnGroups, iTableConfigItem } from '@components/Table/TableConfig';

type SurveyNewTableConfigData = {
    id: string;
    date: string;
    testType: string;
    companyTestedBy: string;
    chainSegmentTestedFor: string;
    itemType: string;
    itemName: string;
    price: string;
    image: boolean;
    exclusive: boolean;
    desiredSample: string;
    priority: string;
};

const SurveyNewTableConfig: FCX<{
    data: SurveyNewTableConfigData[];
}> = ({
    data,
    children
}) => {
    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'id',
                sortingColName: 'id',
                title: 'ID',
                Value: ({ index }) => <>{data?.[index]?.id}</>,
                styles: { width: '50px' },
            },
            {
                key: 'date',
                sortingColName: 'date',
                title: 'Date',
                Value: ({ index }) => <>{formatDefaultDate(data?.[index]?.date)}</>,
                styles: { width: '70px' },
            },
            {
                key: 'testType',
                sortingColName: 'testType',
                title: 'Test Type',
                styles: { width: '90px' },
                Value: ({ index }) => <>{data?.[index]?.testType}</>,
            },
            {
                key: 'companyTestedBy',
                sortingColName: 'companyTestedBy',
                title: 'Company (Tested By)',
                Value: ({ index }) => <>{data?.[index]?.companyTestedBy}</>,
                styles: { width: '80px' },
            },
            {
                key: 'chainSegmentTestedFor',
                sortingColName: 'chainSegmentTestedFor',
                title: 'Chain/Segment (Tested For)',
                Value: ({ index }) => <>{data?.[index]?.chainSegmentTestedFor}</>,
                styles: { width: '90px' },
            },
            {
                key: 'itemType',
                sortingColName: 'itemType',
                title: 'Item Type',
                Value: ({ index }) => <>{data?.[index]?.itemType}</>,
                styles: { width: '90px' },
            },
            {
                key: 'itemName',
                sortingColName: 'itemName',
                title: 'Total Items',
                Value: ({ index }) => <>{data?.[index]?.itemName}</>,
            },
            {
                key: 'price',
                sortingColName: 'price',
                title: 'Price',
                Value: ({ index }) => <>{data?.[index]?.price}</>,
                styles: { width: '70px' },
            },
            {
                key: 'image',
                sortingColName: 'image',
                title: 'Image',
                Value: ({ index }) => <>{data?.[index]?.image ? 'Y' : 'N'}</>,
                styles: { width: '50px' },
            },
            {
                key: 'exclusive',
                sortingColName: 'exclusive',
                title: 'Exclusive',
                Value: ({ index }) => <>{data?.[index]?.exclusive ? 'Y' : 'N'}</>,
                styles: { width: '50px' },
            },
            {
                key: 'desiredSample',
                sortingColName: 'desiredSample',
                title: 'Desired Sample',
                Value: ({ index }) => <>{data?.[index]?.desiredSample}</>,
                styles: { width: '90px' },
            },
            {
                key: 'priority',
                sortingColName: 'priority',
                title: 'Priority',
                Value: ({ index }) => <>{data?.[index]?.priority}</>,
                styles: { width: '90px' },
            },
            {
                key: 'action',
                sortingColName: 'action',
                styles: { width: '30px' },
                title: <IconTrash color="#FFA533" />,
                Value: ({ index }) => (
                    <IconButton
                        onClick={() => {
                            console.log('Delete', data?.[index]);
                        }}
                    >
                        <IconTrash color="#FFA533" />
                    </IconButton>
                ),
            },
        ],
        [data],
    );

    const configColumnGroups = useMemo(
        (): iTableConfigColumnGroups[] => [
            {
                title: 'User Settings',
                columnSpan: 10,
                key: 'userSettings',
            },
            {
                title: 'Admin Settings',
                columnSpan: 2,
                key: 'adminSettings',
            },
            {
                title: '',
                columnSpan: 1,
                key: 'actions',
            },
        ],
        [],
    );

    return (
        <TableContext.Provider value={{ config: tableConfig, configColumnGroups }}>{children}</TableContext.Provider>
    );
};

export default SurveyNewTableConfig;
