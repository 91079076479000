import { useCallback } from 'react';

import { Header } from '@vms/Header';
import { IconPlus } from '@svg/index';
import Button from '@components/Button';
import { Table } from '@components/Table';
import { TextInput } from '@components/Inputs';
import { useGetSurveys } from '@core/api/useSurveys';
import useTableQuickSearch from '@core/hooks/useTableQuickSearch';

import SurveysTableConfig from './SurveysTableConfig';
import { ButtonType } from '@components/Button/Button';
import { Route } from '@core/routes/Route';

const Surveys = () => {
    const {
        data: surveysData,
        isLoading
    } = useGetSurveys();

    const {
        searchValue,
        setSearchValue,
        searchResults
    } = useTableQuickSearch(surveysData ?? [], (s) => s?.name);

    // const onAddNewSurvey = useCallback(() => {
    //     // Add new survey
    // }, []);

    const onLiveSurveyExport = useCallback(() => {
        // Export live survey
    }, []);

    return (
        <div className="Surveys">
            <Header
                rightContent={
                    <div className="ChainItems__actions">
                        <Button type={ButtonType.Link} modifiers={['h-tall']} link={Route.SurveyNew}>
                            <IconPlus />
                            New Survey
                        </Button>
                        <Button modifiers={['h-tall']} onClick={onLiveSurveyExport}>
                            Live Survey Export
                        </Button>
                        <TextInput value={searchValue} setValue={setSearchValue} placeholder="Search surveys" />
                    </div>
                }
            >
                Surveys
            </Header>

            <SurveysTableConfig data={searchResults}>
                <Table rowsCount={searchResults.length} isLoading={isLoading} />
            </SurveysTableConfig>
        </div>
    );
};

export default Surveys;
