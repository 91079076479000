import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFCD00',
            dark: '#FFA533',
        },
        text: {
            primary: '#212121',
        },
    },
});

export default theme;
