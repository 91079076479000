import useAxios from '@core/hooks/useFetch';
import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@core/models';

import { paths, components } from './api';

const GET_SURVEYS = '/api/Survey/GetAllExtended';
const GET_SURVEY_DETAILS = '/api/Survey/GetAllExtended';
const GET_SURVEYS_CONFIG = '/api/Survey/GetDefaultConfiguration';

type GetSurveys = paths[typeof GET_SURVEYS][MethodsEnum.GET];
export type GetSurveysResponse = GetSurveys[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];
export type ExtendedSurvey = components['schemas']['SurveysExtendedListDto'];

export function useGetSurveys() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetSurveysResponse>({
        url: GET_SURVEYS,
        method: MethodsEnum.GET,
    });

    return { data, isLoading, error, doFetch } as const;
}

export function useGetSurveyDetails(surveyId?: string) {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetSurveysResponse>({
        url: `${GET_SURVEY_DETAILS}/${surveyId}`,
        method: MethodsEnum.GET,
    });

    return { data, isLoading, error, doFetch } as const;
}

export function useGetSurveysConfig() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios({
        url: GET_SURVEYS_CONFIG,
        method: MethodsEnum.GET,
    });

    return { data, isLoading, error, doFetch } as const;
}
